import $ from 'jquery'
import Charts from './charts'

const renderShareUrl = (result, name, url) => {
  return `https://twitter.com/intent/tweet?text=${result.description}&url=${url}&hashtags=ビバレン`
}

export default class Chemistry2024 {
  constructor() { }

  initialize() {
    this.$el = $('.js-chemistry2024')
    if(this.$el.length <= 0) return
    const charts = new Charts()
    charts.initialize(data, renderShareUrl)
  }

  renderShareUrl
}

const data = {
  questions: [
    [
      {
        title: '今読みたい本は？',
        answers: [
          {
            text: '漫画',
            score: 1
          },
          {
            text: '小説',
            score: 2
          },
          {
            text: 'エッセイ',
            score: 4
          }
        ]
      },
      {
        title: '散歩中にある物を見つけました。それは何？',
        answers: [
          {
            text: 'ハンカチ',
            score: 1
          },
          {
            text: '財布',
            score: 2
          },
          {
            text: '四葉のクローバー',
            score: 4
          }
        ]
      },
      {
        title: '不思議な箱を見つけました。箱の中には何が入っている？',
        answers: [
          {
            text: '宝石',
            score: 1
          },
          {
            text: '手紙',
            score: 2
          },
          {
            text: '鍵',
            score: 4
          }
        ]
      },
    ], [
      {
        title: '辛い食べ物といえば？',
        answers: [
          {
            text: 'キムチ',
            score: 8
          },
          {
            text: 'カレー',
            score: 16
          },
          {
            text: '麻婆豆腐',
            score: 32
          }
        ]
      },
      {
        title: '花瓶に花が飾ってあります。何本ある？',
        answers: [
          {
            text: '1本',
            score: 8
          },
          {
            text: '5本',
            score: 16
          },
          {
            text: '20本',
            score: 32
          }
        ]
      },
      {
        title: '魔女に姿を変えられてしまいました。何になった？',
        answers: [
          {
            text: '犬',
            score: 8
          },
          {
            text: '猫',
            score: 16
          },
          {
            text: 'ヘビ',
            score: 32
          }
        ]
      },
    ], [
      {
        title: '空を飛ぶならどうやって飛ぶ？',
        answers: [
          {
            text: '魔法の絨毯',
            score: 64
          },
          {
            text: 'ヘリコプター',
            score: 128
          },
          {
            text: '翼が生える',
            score: 256
          }
        ]
      },
      {
        title: '夜空を見上げたとき、最初に目に入るものは？',
        answers: [
          {
            text: '星',
            score: 64
          },
          {
            text: '月',
            score: 128
          },
          {
            text: '雲',
            score: 256
          }
        ]
      },
      {
        title: '古い日記を見つけました。日記に書かれているのは？',
        answers: [
          {
            text: '冒険の記録',
            score: 64
          },
          {
            text: '恋愛の物語',
            score: 128
          },
          {
            text: '未来の予言',
            score: 256
          }
        ]
      },
    ]
  ],
  results: [
    {
      scores: [73,137,292],
      image_url: '',
      name: 'MONET',
      description: 'あなたと一番相性がいいのは…「MONET」でした！あなたもやってみよう！',
      key: 'monet'
    },{
      scores: [76,84],
      image_url: '',
      name: 'CATE',
      description: 'あなたと一番相性がいいのは…「CATE」でした！あなたもやってみよう！',
      key: 'cate'
    },{
      scores: [161,266,97],
      image_url: '',
      name: 'MIKERA',
      description: 'あなたと一番相性がいいのは…「MIKERA」でした！あなたもやってみよう！',
      key: 'mikera'
    },{
      scores: [82,100,276],
      image_url: '',
      name: 'RIAM',
      description: 'あなたと一番相性がいいのは…「RIAM」でした！あなたもやってみよう！',
      key: 'riam'
    },{
      scores: [164,265,162],
      image_url: '',
      name: 'HIROMI',
      description: 'あなたと一番相性がいいのは…「HIROMI」でした！あなたもやってみよう！',
      key: 'hiromi'
    },{
      scores: [81,138],
      image_url: '',
      name: 'KYOUYA',
      description: 'あなたと一番相性がいいのは…「KYOUYA」でした！あなたもやってみよう！',
      key: 'kyouya'
    },{
      scores: [140,289,268],
      image_url: '',
      name: 'LUVNOSUKE',
      description: 'あなたと一番相性がいいのは…「LUVNOSUKE」でした！あなたもやってみよう！',
      key: 'luvnosuke'
    },{
      scores: [145,273],
      image_url: '',
      name: 'AKASHI',
      description: 'あなたと一番相性がいいのは…「AKASHI」でした！あなたもやってみよう！',
      key: 'akashi'
    },{
      scores: [148,274,74],
      image_url: '',
      name: 'SHIMBA',
      description: 'あなたと一番相性がいいのは…「SHIMBA」でした！あなたもやってみよう！',
      key: 'shimba'
    },{
      scores: [98,290,146],
      image_url: '',
      name: 'MEGURU',
      description: 'あなたと一番相性がいいのは…「MEGURU」でした！あなたもやってみよう！',
      key: 'meguru'
    },
  ]
}
