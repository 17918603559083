// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import Rails from "@rails/ujs"
import './lib/scrolla'
import Loader from './loader'
import Top from './top'
import Votes from './votes'
import Members from './members'
import HamburgerMenu from './hamburgerMenu'
import $ from 'jquery'
import modal from './modal'
import popup from './popup'
import slider from './slider'
import scrollableLink from './scrollableLink'
import CopyBtn from './copyBtn'
import scrollBar from './scrollBar'
import {playAudio} from './sound'
import PlatinumGlanz from './platinumGlanz'
import {scrollLoader} from './scrollLoader'
import {parallax} from './parallax'
import './accordion'
import Xmas2023 from './xmas2023'
import Chemistry2024 from './chemistry2024'
import Whiteday2024 from './whiteday2024'
import DestinyMV from './destinyMV'

class Application {
  constructor() { }

  initialize() {
    new HamburgerMenu().initialize()
    this.loader = new Loader().initialize()
    new CopyBtn().initialize()
    modal.initialize()
    popup.initialize()
    slider.initialize()
    scrollableLink.initialize()
    scrollBar.initialize()
    new Top().initialize()
    new Votes().initialize()
    new Members().initialize()
    new PlatinumGlanz().initialize()
    new Xmas2023().initialize()
    new Whiteday2024().initialize()
    new DestinyMV().initialize()
    new Chemistry2024().initialize()

    $('.scrolla').scrolla({
      mobile: true,
      once: true
    })
    window.addEventListener('load', () => {
      scrollLoader()
      parallax()
      const $body = $('.preload,body')
      $body.addClass('loaded')
      $body.removeClass('preload')
      this.loader.hide()

      const $soundBtn = $('.js-sound-play-btn')
      const onPlayEnd = () => {
        $soundBtn.removeClass('sound-playing')
      }
      $soundBtn.on('click', (e) => {
        const el = $(e.currentTarget)
        const soundPath = el.attr('data-sound-src')
        $soundBtn.removeClass('sound-playing')
        el.addClass('sound-playing')
        playAudio(soundPath, onPlayEnd)
      })
    })
  }
}

document.addEventListener('DOMContentLoaded', () => {
  new Application().initialize()
})
